import { Col, Form, Row, Spinner } from "reactstrap";
import { FormikField } from "./index";
import i18n from "i18n";
import { useEffect, useState } from "react";
import classeNames from "classnames";
import useAxios from "axios-hooks";

let index = 0;
const getValues = values => (Array.isArray(values) ? values : []);

const getExclude = values =>
  Array.isArray(values)
    ? values.map(item => {
        id: item?.id;
      })
    : [];

const ArrayFormik = props => {
  const {
    fields,
    value: _values,
    data: navData,
    touched,
    error,
    onChange,
    disabled,
    startWithOne,
    source,
    resource,
    id,
  } = props;
  const [current, setCurrent] = useState(1);
  const [values, setValues] = useState(
    getValues(_values).map(item => ({ index: index++, ...item }))
  );
  const take = 10;
  const skip = take * Math.max(0, current - 1);
  const total = Math.floor((values.length + take - 1) / take);
  let listLoading = false;
  let listError = false;

  if (id !== undefined) {
    const url = "/" + resource + "/" + id + "/" + source + "?take=10&skip=-1";
    const [
      { data, loading, error: fetchError, response },
      refetch,
      manualCancel,
    ] = useAxios(
      {
        url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          "Access-Control-Allow-Origin": "no-cors",
        },
      },
      {
        useCache: false,
      }
    );
    listLoading = loading;
    listError = fetchError;
    useEffect(() => {
      if (data !== undefined && !loading)
        onChange(data.map(item => ({ index: index++, ...item })));
    }, [data]);
    useEffect(() => {
      if (data !== undefined && !loading) setValues(_values);
    }, [data]);
  }

  useEffect(() => {
    if (_values)
      setValues(getValues(_values).map(item => ({ index: index++, ...item })));
  }, [_values]);

  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);
  const onPaginationChange = value => {
    switch (value) {
      case ">":
        setCurrent(Math.max(1, current - 1));
        break;
      case ">>":
        setCurrent(1);
        break;
      case "<":
        setCurrent(Math.min(total, current + 1));
        break;
      case "<<":
        setCurrent(total);
        break;
      default:
        setCurrent(value);
        break;
    }
    onChange(values);
  };

  if (values.length === 0 && !!startWithOne) {
    const modifiedRows = [...values, { index: index++ }];
    onChange(modifiedRows);
  }
  if (listLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner color="primary" />
      </div>
    );

  const onAddFormRow = () => {
    const modifiedRows = [...values, { index: index++ }];
    onChange(modifiedRows);
    const newTotal = Math.floor((values.length + take) / take);
    setCurrent(newTotal);
    if (id !== undefined) setValues(_values);
  };

  const onDeleteFormRow = index => {
    if (values.length > 0) {
      const modifiedRows = [...values].filter(row => row.index !== index);
      onChange(modifiedRows);

      const newTotal = Math.floor((values.length + take - 2) / take);
      if (current > newTotal) setCurrent(current - 1);
    }
  };

  const RenderDelete = ({ row, index }) => {
    return (
      <Col className="mt-3 p-0">
        <button
          className="btn btn-link p-0 m-0"
          disabled={
            !!fields.find(
              x => x.source === "order_quantity" && x.disabled === true
            )
          }
          onClick={() => onDeleteFormRow(row.index)}
        >
          <i className="mdi mdi-delete font-size-24 " />
        </button>
      </Col>
    );
  };

  const renderPageNumbers = () => {
    let data = [];
    let pagination = [];
    for (let i = total; i > 0; i--) data = [...data, i];

    if (current - 2 >= 1) {
      pagination.push(current - 2);
    }
    
    if (current - 1 >= 1) {
      pagination.push(current - 1);
    }
    pagination.push(current);

    if (current + 1 <= total) {
      pagination.push(current + 1);
    }

    if (current + 2 <= total) {
      pagination.push(current + 2);
    }

    return pagination.map(e => {
      return (
        <li className={"page-item" + (e === current ? " active" : "")}>
          <a
            className="page-link z-index-0"
            onClick={() => onPaginationChange(e)}
          >
            {e}
            <span className="sr-only">(e)</span>
          </a>
        </li>
      );
    });
  };

  return (
    <Form className="repeater" encType="multipart/form-data">
      <div
        className={classeNames({
          " is-touched": !!touched,
          " is-invalid": !!error,
        })}
      >
        {values
          .filter((_, i) => i >= skip && i < skip + take)
          .map((row, index) => (
            <Row className="align-items-center" key={`row-${index}`}>
              {fields.map(field => {
                const handleChange = value => {
                  const item = values.find(item => item.index === row.index);
                  if (item) {
                    const newValues = [
                      ...values.filter(item => item.index !== row.index),
                      {
                        ...item,
                        [field.source]: value,
                      },
                    ].sort((a, b) => a.index - b.index);
                    onChange(newValues);
                  }
                };
                return (
                  <Col className="mb-3" key={`${row.id}-${field.source}`}>
                    <div
                      className={classeNames({
                        " is-touched": !!touched,
                        " is-invalid": !!error,
                      })}
                    >
                      <FormikField
                        values={values}
                        value={row[field.source]}
                        direction="column"
                        disabled={field.disabled}
                        onChange={value => handleChange(value)}
                        {...field}
                      />
                    </div>
                    {Array.isArray(error) &&
                      Array.isArray(touched) &&
                      touched[index] &&
                      error[index] && (
                        <div className="invalid-feedback">
                          {" "}
                          {error[index][field.source]}{" "}
                        </div>
                      )}
                  </Col>
                );
              })}
              <Col xl="1">
              <RenderDelete row={row} index={index} />
              </Col>
            </Row>
          ))}
      </div>

      <div className="d-flex">
        <input
          type="button"
          disabled={
            !!fields.find(
              x => x.source === "order_quantity" && x.disabled === true
            )
          }
          className="btn btn-success me-5 px-5 py-2"
          value={i18n.t("add")}
          onClick={() => onAddFormRow()}
        />
        {values.length > take && (
          <ul className="pagination m-0">
            {current >= 4 && <li className="page-item">
              <a className="page-link" onClick={() => onPaginationChange(">>")}>
                <i className={"mdi " + i18n.t("mdi-chevron-double-left")} />
              </a>
            </li>}
            {current > 1 && <li className="page-item">
              <a className="page-link" onClick={() => onPaginationChange(">")}>
                <i className={"mdi " + i18n.t("mdi-chevron-left")} />
              </a>
            </li>}
            {renderPageNumbers()}
            {current < total && <li className="page-item">
              <a className="page-link" onClick={() => onPaginationChange("<")}>
                <i className={"mdi " + i18n.t("mdi-chevron-right")} />
              </a>
            </li>}
           {current < total - 3 && <li className="page-item">
              <a className="page-link" onClick={() => onPaginationChange("<<")}>
                <i className={"mdi " + i18n.t("mdi-chevron-double-right")} />
              </a>
            </li>}
          </ul>
        )}
      </div>
      <div className="invalid-feedback">{!Array.isArray(error) && error}</div>
    </Form>
  );
};

ArrayFormik.defaultProps = {
  value: [],
};

export default ArrayFormik;
