import React, { useState } from "react";
import api from "api_helper";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { useHistory } from "react-router";
import Countdown from "react-countdown";
import i18n from "i18n";
import { Link } from "react-router-dom";
import profile from "assets/images/profile-img.png";
import logo from "assets/images/atmatah.png";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [emailSet, setEmailSet] = useState(true);

  const [code, setCode] = useState("");
  const [codeSet, setCodeSet] = useState(false);

  const [codeSetMsg, setCodeSetMsg] = useState("");
  const [countdown, setCountdown] = useState(0);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordSet, setnewPasswordSet] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  const handleEmailClick = e => {
    e.preventDefault();
    api
      .post("/forgot-password", {
        email: email,
      })
      .then(res => {
        if (res.status === 200) {
          setEmailSet(false);
          setCodeSet(true);
        }
      });
  };

  const Completionist = () => <span>You are good to go!</span>;
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          You need {" " + hours}:{minutes}:{seconds + " "}
          for your next attempt.
        </span>
      );
    }
  };

  const handleCodeClick = e => {
    e.preventDefault();
    const res = api
      .post("/verify-code", {
        code: code,
        email: email,
      })
      .then(res => {
        if (res.data?.valid) {
          setCodeSet(false);
          setnewPasswordSet(true);
        } else if (res.data?.attempts) setCodeSetMsg(res.data.attempts);
        else {
          setCountdown(res.data?.reset_duration);
        }
      });
  };

  const handlePasswordClick = e => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      api
        .post("/reset-password", {
          code: code,
          email: email,
          password: newPassword,
        })
        .then(res => {
          if (res.status === 200) {
            history.push("/login");
          }
        });
    } else {
      setError(true);
    }
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div
        className="account-pages my-5 pt-sm-5"
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          {i18n.t("forget_password")} !
                        </h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <div className="container ">
                      {emailSet && !codeSet && !newPassword && (
                        <div className="page-content">
                          <form
                            onSubmit={handleEmailClick}
                            className="d-flex flex-column justify-content-center align-items-center"
                          >
                            <Label source="email">
                              {" "}
                              {i18n.t("enter_your_email")}{" "}
                            </Label>
                            <input
                              placeholder={i18n.t("email")}
                              className="form-control"
                              type="email"
                              value={email}
                        
                              onChange={e => setEmail(e.target.value)}
                              style={{ width: "300px" }}
                            />
                            <button className="float-right mt-1 btn btn-primary">
                              {i18n.t("submit")}
                            </button>
                          </form>
                        </div>
                      )}
                      {!emailSet && codeSet && !newPassword && (
                        <div className="page-content">
                          <form
                            onSubmit={handleCodeClick}
                            className="d-flex flex-column justify-content-center align-items-center"
                          >
                            <Label source="code">
                              {" "}
                              Code has been sent to your email:{" "}
                            </Label>
                            <input
                              placeholder="Code..."
                              className="form-control"
                              type="text"
                              value={code}
                              onChange={e => setCode(e.target.value)}
                              style={{ width: "300px" }}
                            />
                            <button className="float-right mt-1 btn btn-primary">
                              Submit
                            </button>
                            {!!codeSetMsg && (
                              <p>
                                {"You have " + codeSetMsg + " attempts left"}
                              </p>
                            )}
                            {!!countdown && (
                              <Countdown
                                date={Date.now() + countdown * 1000}
                                renderer={renderer}
                              />
                            )}
                          </form>
                        </div>
                      )}

                      {!emailSet && !codeSet && newPasswordSet && (
                        <div className="page-content">
                          <form
                            onSubmit={handlePasswordClick}
                            className="d-flex flex-column justify-content-center align-items-center"
                          >
                            <Label source="password">
                              {" "}
                              Enter the new Password:{" "}
                            </Label>
                            <input
                              className="form-control mt-2"
                              placeholder="New password"
                              type="password"
                              value={newPassword}
                              onChange={e => setNewPassword(e.target.value)}
                              style={{ width: "300px" }}
                            />
                            <input
                              className="form-control mt-2"
                              placeholder="Confirm password"
                              type="password"
                              value={confirmPassword}
                              onChange={e => setConfirmPassword(e.target.value)}
                              style={{ width: "300px" }}
                            />
                            {error && (
                              <small className="text-danger">
                                Passwords Don't match
                              </small>
                            )}
                            <button className="float-right mt-1 btn btn-primary">
                              Submit
                            </button>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPassword;
