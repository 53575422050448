import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useRef, useState } from "react";
import { userLogin, Notification, useNotify } from "react-admin";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";

//redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/atmatah.png";
import i18n from "i18n";
import { messaging } from "../../init-fcm";
import authProvider from "authProvider";
import { useHistory } from "react-router";

const Login = props => {
  // const ref = useRef();
  const [loading, setLoading] = useState(false);
  // const history = useHistory();
  const notify = useNotify();
  
  if(localStorage.getItem('token')){
    {
      const canViewDashboard = localStorage
        ?.getItem("permissions")
        ?.includes("view_dashboard");
      if (localStorage.getItem("token")) {
        if (!!canViewDashboard) return (window.location.href = "/dashboard");
        else if (!canViewDashboard) return (window.location.href = "/profile");
      }
    }
  }

  // handleValidSubmit
  const handleValidSubmit =  async (event, values) => {
    event.preventDefault();
    setLoading(true);
    messaging
      .getToken()
      .then(fcmtoken => {
        authProvider
          .login({ ...values, fcmtoken: fcmtoken ?? "" })
          .then(() => {
            if (
              localStorage?.getItem("permissions")?.includes("view_dashboard")
            )
              window.location.href = "/dashboard";
            else window.location.href = "/profile";
          })
          .catch(err => {
            notify(
              i18n.t(
                err.response?.data?.error ||
                  err.response?.data?.message ||
                  err.message
              ),
              "error",
              {
                smart_count: 4,
              }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(function (err) {
        authProvider
          .login({ ...values, fcmtoken: "" })
          .then(() => {
            if (
              localStorage?.getItem("permissions")?.includes("view_dashboard")
            )
              window.location.href = "/dashboard";
            else window.location.href = "/profile";
          })
          .catch(err => {
            notify(
              i18n.t(
                err.response?.data?.error ||
                  err.response?.data?.message ||
                  err.message
              ),
              "error",
              {
                smart_count: 4,
              }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      });
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>{i18n.t("login")}</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div
        className="account-pages my-5 pt-sm-5"
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          {i18n.t("welcome_back")} !
                        </h5>
                        <p>{i18n.t("login_to_continue")}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label={i18n.t("email")}
                          className="form-control"
                          required
                          validate={{
                            required: {
                              value: true,
                              errorMessage: i18n.t("required_field"),
                            },
                            email: {
                              value: true,
                              errorMessage: i18n.t("field_must_be_email"),
                            },
                          }}
                          placeholder={i18n.t("enter_email")}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label={i18n.t("password")}
                          className="form-control"
                          type="password"
                          required
                          validate={{
                            required: {
                              value: true,
                              errorMessage: i18n.t("required_field"),
                            },
                          }}
                          placeholder={i18n.t("enter_password")}
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              {
                                <Spinner
                                  style={{ width: "20px", height: "20px" }}
                                />
                              }
                            </>
                          ) : (
                            i18n.t("login")
                          )}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          {i18n.t("forgot_your_password")}
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Notification />
        </Container>
      </div>
    </React.Fragment>
  );
};

Login.propTypes = {
  history: PropTypes.object,
  userLogin: PropTypes.func.isRequired,
};
export default connect(undefined, { userLogin })(withRouter(Login));
