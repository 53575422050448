import { useEffect } from "react";
import { Admin, Resource } from "react-admin";

import {
  WarehousesCreate,
  WarehousesEdit,
  WarehousesList,
  WarehousesView,
} from "./pages/CRUD/warehouses/warehouses.view";
import {
  ItemsCreate,
  ItemsEdit,
  ItemsList,
  ItemsView,
} from "./pages/CRUD/items/items.view";
import {
  ProductsCreate,
  ProductsEdit,
  ProductsList,
  ProductsView,
} from "./pages/CRUD/products/products.view";
import {
  UsersCreate,
  UsersEdit,
  UsersList,
  UsersView,
} from "./pages/CRUD/users/users.view";
import {
  PipelinesCreate,
  PipelinesEdit,
  PipelinesList,
  PipelinesView,
} from "./pages/CRUD/pipelines/pipelines.view";
import {
  StagesCreate,
  StagesEdit,
  StagesList,
  StagesView,
} from "./pages/CRUD/stages/stages.view";
import {
  OrdersCreate,
  OrdersEdit,
  OrdersList,
  OrdersView,
} from "./pages/CRUD/orders/orders.view";
import {
  PurchaseOrdersCreate,
  PurchaseOrdersEdit,
  PurchaseOrdersView,
  PurchaseOrdersList,
} from "./pages/CRUD/purchase_orders/purchase_orders.view";
import {
  SpacesCreate,
  SpacesList,
  SpacesEdit,
  SpacesView,
} from "./pages/CRUD/spaces";
import {
  ManufactureOrdersCreate,
  ManufactureOrdersEdit,
  ManufactureOrdersList,
  ManufactureOrdersView,
} from "./pages/CRUD/manufacture-orders/manufacture-order.view";
import {
  CategoriesCreate,
  CategoriesEdit,
  CategoriesList,
  CategoriesView,
} from "./pages/CRUD/categories/categories.view";
import VerticalLayout from "./components/VerticalLayout";
import { needinessCalculatorCreate } from "./pages/CRUD/needinessCalculator/needinessCalculator.view";

import { dataProvider, history } from "./createAdminStore";
import Dashboard from "./pages/Dashboard";
import { Tasks, TaskView } from "./pages/CRUD/tasks/tasks.view";
import {
  RolesCreate,
  RolesEdit,
  RolesList,
  RolesView,
} from "./pages/CRUD/roles/roles.view";
import authProvider from "authProvider";
import Login from "pages/Authentication/Login";
import notFound from "pages/notFound";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import { Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./pages/Profile";
import ForgetPassword from "./pages/ForgetPassword";
import i18n from "i18n";

const AppCRUD = props => {
  {
    !localStorage?.token && history.push("/login");
  }
  useEffect(() => {
    navigator.serviceWorker.addEventListener("message", message => {
      const notify = message?.data["firebase-messaging-msg-data"]?.data;

      toast.info(
        <a
          href={`/${JSON.parse(notify?.payload).resource}/${
            JSON.parse(notify?.payload).element_id
          }/show`}
          style={{ width: "100%", height: "100%" }}
        >
          {i18n.t(JSON.parse(notify?.payload).description)}
        </a>,
        {
          position: `top-${
            localStorage.I18N_LANGUAGE == "ar" ? "left" : "right"
          }`,
          hideProgressBar: false,
          newestOnTop: false,
          closeOnClick: false,
          rtl: false,
          draggable: false,
          pauseOnHover: true,
        }
      );
    });
    return () =>
      navigator.serviceWorker.removeEventListener("message", message =>
        console.log({ message })
      );
  }, []);

  const AllowedIf = permission => {
    return localStorage?.getItem("permissions")?.includes(permission);
  };

  return (
    <>
      <Admin
        loginPage={Login}
        catchAll={notFound}
        dashboard={Login}
        authProvider={authProvider}
        dataProvider={dataProvider}
        history={history}
        layout={VerticalLayout}
        customRoutes={[
          <Route exact path="/profile" component={Profile} />,
          <Route exact path="/forgot-password" component={ForgetPassword} />,
        ]}
      >
        <Resource
          name="neediness/calculator"
          show={notFound}
          list={notFound}
          edit={notFound}
          create={
            AllowedIf("list_my_tasks") ? needinessCalculatorCreate : notFound
          }
        />

        <Resource
          name="spaces"
          show={
            localStorage != undefined &&
            JSON.parse(localStorage.getItem("user"))?.role === "admin"
              ? SpacesView
              : notFound
          }
          list={
            localStorage != undefined &&
            JSON.parse(localStorage.getItem("user"))?.role === "admin"
              ? SpacesList
              : notFound
          }
          create={
            localStorage != undefined &&
            JSON.parse(localStorage.getItem("user"))?.role === "admin"
              ? SpacesCreate
              : notFound
          }
          edit={
            localStorage != undefined &&
            JSON.parse(localStorage.getItem("user"))?.role === "admin"
              ? SpacesEdit
              : notFound
          }
        />
        <Resource
          name="dashboard"
          list={AllowedIf("view_dashboard") ? Dashboard : notFound}
        />
        <Resource
          name="warehouses"
          show={
            AllowedIf("list_all_warehouses") || AllowedIf("list_my_warehouses")
              ? WarehousesView
              : notFound
          }
          list={
            AllowedIf("list_all_warehouses") || AllowedIf("list_my_warehouses")
              ? WarehousesList
              : notFound
          }
          create={AllowedIf("create_warehouses") ? WarehousesCreate : notFound}
          edit={
            ((AllowedIf("list_all_warehouses") ||
              AllowedIf("list_my_warehouses")) &&
              AllowedIf("edit_all_warehouses")) ||
            AllowedIf("edit_my_warehouses")
              ? WarehousesEdit
              : notFound
          }
        />

        <Resource
          name="items"
          show={AllowedIf("list_all_items") ? ItemsView : notFound}
          list={AllowedIf("list_all_items") ? ItemsList : notFound}
          create={AllowedIf("create_items") ? ItemsCreate : notFound}
          edit={
            AllowedIf("list_all_items") && AllowedIf("edit_all_items")
              ? ItemsEdit
              : notFound
          }
        />

        <Resource
          name="products"
          show={AllowedIf("list_all_products") ? ProductsView : notFound}
          list={AllowedIf("list_all_products") ? ProductsList : notFound}
          create={AllowedIf("create_users") ? ProductsCreate : notFound}
          edit={
            AllowedIf("list_all_products") && AllowedIf("edit_all_products")
              ? ProductsEdit
              : notFound
          }
        />

        <Resource
          name="users"
          show={AllowedIf("list_all_users") ? UsersView : notFound}
          list={AllowedIf("list_all_users") ? UsersList : notFound}
          create={AllowedIf("create_users") ? UsersCreate : notFound}
          edit={
            (AllowedIf("list_all_users") || AllowedIf("list_my_users")) &&
            AllowedIf("edit_all_users")
              ? UsersEdit
              : notFound
          }
        />

        <Resource
          name="pipelines"
          show={AllowedIf("list_all_pipelines") ? PipelinesView : notFound}
          list={AllowedIf("list_all_pipelines") ? PipelinesList : notFound}
          create={AllowedIf("create_pipelines") ? PipelinesCreate : notFound}
          edit={
            AllowedIf("list_all_pipelines") && AllowedIf("edit_all_pipelines")
              ? PipelinesEdit
              : notFound
          }
        />

        <Resource
          name="stages"
          show={AllowedIf("list_all_stages") ? StagesView : notFound}
          list={AllowedIf("list_all_stages") ? StagesList : notFound}
          create={AllowedIf("create_stages") ? StagesCreate : notFound}
          edit={
            AllowedIf("list_all_stages") && AllowedIf("edit_all_stages")
              ? StagesEdit
              : notFound
          }
        />

        <Resource
          name="orders"
          show={
            AllowedIf("list_all_orders") || AllowedIf("list_my_orders")
              ? OrdersView
              : notFound
          }
          list={
            AllowedIf("list_all_orders") || AllowedIf("list_my_orders")
              ? OrdersList
              : notFound
          }
          create={AllowedIf("create_orders") ? OrdersCreate : notFound}
          edit={
            (AllowedIf("list_all_orders") && AllowedIf("edit_all_orders")) ||
            AllowedIf("list_my_purchase_orders") ||
            AllowedIf("edit_my_orders")
              ? OrdersEdit
              : notFound
          }
        />

        <Resource
          name="purchase_orders"
          show={
            AllowedIf("list_all_purchase_orders") ||
            AllowedIf("list_my_purchase_orders")
              ? PurchaseOrdersView
              : notFound
          }
          list={
            AllowedIf("list_all_purchase_orders") ||
            AllowedIf("list_my_purchase_orders")
              ? PurchaseOrdersList
              : notFound
          }
          create={
            AllowedIf("create_purchase_orders")
              ? PurchaseOrdersCreate
              : notFound
          }
          edit={
            (AllowedIf("list_all_purchase_orders") &&
              AllowedIf("edit_all_purchase_orders")) ||
            AllowedIf("list_my_purchase_orders") ||
            AllowedIf("edit_my_purchase_orders")
              ? PurchaseOrdersEdit
              : notFound
          }
        />

        <Resource
          name="categories"
          show={AllowedIf("list_all_categories") ? CategoriesView : notFound}
          list={AllowedIf("list_all_categories") ? CategoriesList : notFound}
          create={AllowedIf("create_categories") ? CategoriesCreate : notFound}
          edit={
            AllowedIf("list_all_categories") && AllowedIf("edit_all_categories")
              ? CategoriesEdit
              : notFound
          }
        />

        <Resource
          name="roles"
          show={
            localStorage != undefined &&
            JSON.parse(localStorage.getItem("user"))?.role === "admin"
              ? RolesView
              : notFound
          }
          list={
            localStorage != undefined &&
            JSON.parse(localStorage.getItem("user"))?.role === "admin"
              ? RolesList
              : notFound
          }
          create={
            localStorage != undefined &&
            JSON.parse(localStorage.getItem("user"))?.role === "admin"
              ? RolesCreate
              : notFound
          }
          edit={
            localStorage != undefined &&
            JSON.parse(localStorage.getItem("user"))?.role === "admin"
              ? RolesEdit
              : notFound
          }
        />

        <Resource
          name="manufacture_orders"
          show={
            AllowedIf("list_all_manufacture_orders")
              ? ManufactureOrdersView
              : notFound
          }
          list={
            AllowedIf("list_all_manufacture_orders")
              ? ManufactureOrdersList
              : notFound
          }
          create={
            AllowedIf("create_manufacture_orders")
              ? ManufactureOrdersCreate
              : notFound
          }
          edit={
            AllowedIf("list_all_manufacture_orders") &&
            AllowedIf("edit_all_manufacture_orders")
              ? ManufactureOrdersEdit
              : notFound
          }
        />

        <Resource
          name="tasks"
          show={
            AllowedIf("list_my_tasks") || AllowedIf("list_all_tasks")
              ? TaskView
              : notFound
          }
          list={
            AllowedIf("list_my_tasks") || AllowedIf("list_all_tasks")
              ? Tasks
              : notFound
          }
        />

        <Resource name="notifications" />

        {/* <Resource
                    name="profile"
                /> */}
        <div >
          <ToastContainer />
        </div>
      </Admin>
    </>
  );
};

export default AppCRUD;
